<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              v-model="tableTemplates.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="primary"
            @click="onShowAddFormTemplate"
          >
            Add Template
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="tableTemplates"
        small
        hover
        responsive
        class="mt-2"
        :per-page="tableTemplates.perPage"
        :current-page="tableTemplates.currentPage"
        :items="myProvider"
        :fields="tableTemplates.fields"
        :sort-by.sync="tableTemplates.sortBy"
        :sort-desc.sync="tableTemplates.sortDesc"
        :sort-direction="tableTemplates.sortDirection"
        :filter="tableTemplates.filter"
        :filter-included-fields="tableTemplates.filterOn"
        show-empty
      >
        <template #cell(index)="row">
          {{ tableTemplates.currentPage * tableTemplates.perPage - tableTemplates.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(use_file_input)="row">
          {{ row.item.use_file_input == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(template_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              class="mr-1"
              size="sm"
              @click="onShowEditFormTemplate(row.item)"
            >
              Edit
            </b-button>
            <b-button
              size="sm"
              variant="success"
              @click="onShowUseFormTemplate(row.item)"
            >
              Use Template
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableTemplates.perPage"
              :options="tableTemplates.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableTemplates.currentPage"
            :total-rows="tableTemplates.totalRows"
            :per-page="tableTemplates.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-template"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="modalTemplateTitle"
      @ok="onValidateFormTemplate"
    >

      <ValidationObserver
        ref="formTemplate"
      >
        <form
          role="formTemplate"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="template name"
                vid="template_name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label
                    for="template_name"
                  >
                    <strong>
                      Template
                    </strong>
                  </label>
                  <b-input
                    id="template_name"
                    v-model="template.template_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter template name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <div
                v-if="state.editing"
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="template.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Is Active?
                </b-form-checkbox>
              </div>

              <div
                class="form-group"
              >
                <b-form-checkbox
                  id="use_file_input"
                  v-model="template.use_file_input"
                  name="use_file_input"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Use JSON File?
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <ValidationObserver
        ref="formComposition"
      >
        <form
          id="formComposition"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="type"
                vid="type_id"
                rules="required"
              >
                <b-form-group>
                  <label
                    for="type_id"
                  >
                    <strong>
                      Type
                    </strong>
                  </label>
                  <b-form-select
                    id="type_id"
                    v-model="composition.type_id"
                    :options="list.types"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || fetching.types || state.editingComposition"
                    @change="onSelectType"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select type --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="category"
                vid="category_id"
                rules="required"
              >
                <b-form-group>
                  <label
                    for="category_id"
                  >
                    <strong>
                      Category
                    </strong>
                  </label>
                  <b-form-select
                    id="category_id"
                    v-model="composition.category_id"
                    :options="filteredCategories"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || fetching.categories || state.editingComposition"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select category --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="name"
                vid="name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label
                    for="name"
                  >
                    <strong>
                      Name
                    </strong>
                  </label>
                  <b-input
                    id="name"
                    v-model="composition.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="is_required"
                      v-model="composition.is_required"
                      name="is_required"
                      value="1"
                      unchecked-value="0"
                      :disabled="state.busy"
                      switch
                    >
                      Is Required?
                    </b-form-checkbox>
                  </div>
                </div>
                <div>
                  <b-overlay
                    :show="submitting.draft"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button
                      size="sm"
                      variant="success"
                      class="mr-1"
                      type="button"
                      :disabled="state.busy"
                      @click="onSaveComposition"
                    >
                      Save
                    </b-button>
                  </b-overlay>
                  <b-overlay
                    :show="submitting.clear"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1"
                      type="button"
                      :disabled="state.busy"
                      @click="onClearComposition"
                    >
                      Clear
                    </b-button>
                  </b-overlay>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="mt-2"
            >
              <ValidationProvider
                #default="{ errors }"
                name="compositions"
                vid="compositions"
              >
                <b-form-group>
                  <b-input
                    id="compositions"
                    v-model="template.compositions.length"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    hidden
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-table
                ref="tableCompositions"
                :fields="tableCompositions.fields"
                :items="template.compositions"
                responsive
                striped
                hover
              >

                <template #cell(is_parent)="row">
                  <div class="text-nowrap">
                    {{ row.item.is_parent == 1 ? 'YES' : 'NO' }}
                  </div>
                </template>

                <template #cell(is_package)="row">
                  <div class="text-nowrap">
                    {{ row.item.is_package == 1 ? 'YES' : 'NO' }}
                  </div>
                </template>

                <template #cell(is_required)="row">
                  <div class="text-nowrap">
                    {{ row.item.is_required == 1 ? 'YES' : 'NO' }}
                  </div>
                </template>

                <template #cell()="row">
                  <div class="text-nowrap">
                    {{ row.value }}
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div
                    v-if="state.editing"
                    class="text-nowrap"
                  >
                    <b-button
                      variant="success"
                      size="sm"
                      class="mr-1"
                      :disabled="state.busy"
                      @click="onEditComposition(row.item)"
                    >
                      Edit
                    </b-button>
                    <b-button
                      v-if="row.index > 1"
                      size="sm"
                      class="mr-1"
                      @click="swapUpPosition(row.index)"
                    >
                      Move Up
                    </b-button>
                    <b-button
                      v-if="row.index > 0 && row.index < (template.compositions.length - 1)"
                      size="sm"
                      variant="danger"
                      @click="swapDownPosition(row.index)"
                    >
                      Move Down
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="text-nowrap"
                  >
                    <b-button
                      variant="success"
                      size="sm"
                      class="mr-1"
                      :disabled="state.busy"
                      @click="onEditComposition(row.item)"
                    >
                      Edit
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      :disabled="state.busy"
                      @click="onRemoveComposition(row.item)"
                    >
                      Remove
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminTemplateService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminTemplates',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Templates'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        editingComposition: false
      },
      submitting: {
        draft: false,
        final: false,
        clear: false
      },
      fetching: {
        types: false,
        categories: false
      },
      list: {
        types: [],
        categories: []
      },
      composition: {
        id: null,
        name: null,
        type_id: null,
        type_name: null,
        category_id: null,
        category_name: null,
        is_parent: 0,
        is_package: 0,
        is_required: 1
      },
      template: {
        id: null,
        template_name: null,
        use_file_input: 0,
        compositions: [],
        active: 1
      },
      tableTemplates: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'actions', class: 'text-center' },
          { key: 'template_name', label: 'template', sortable: true },
          { key: 'use_file_input', label: 'use JSON file', class: 'text-center' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      },
      tableCompositions: {
        fields: [
          'actions',
          { key: 'name' },
          { key: 'type_name', label: 'type' },
          { key: 'category_name', label: 'category' },
          { key: 'is_parent', class: 'text-center' },
          { key: 'is_package', class: 'text-center' },
          { key: 'is_required', class: 'text-center' }
        ]
      }
    }
  },

  computed: {
    modalTemplateTitle () {
      return this.state.editing ? 'Edit Template' : 'Add Template'
    },

    filteredCategories () {
      if (this.state.editingComposition) {
        return this.list.categories
      }

      return this.list.categories.filter(category => this.template.compositions.filter(composition => composition.category_id === category.value).length < category.maximum_slot)
    }
  },

  watch: {
    'composition.type_id' (type) {
      const index = this.list.types.findIndex(find => find.value === type)
      if (index >= 0) {
        this.composition.type_name = this.list.types[index].text
      }
    },

    'composition.category_id' (category) {
      const index = this.list.categories.findIndex(find => find.value === category)
      if (index >= 0) {
        const category = this.list.categories[index]
        this.composition.is_parent = category.is_parent
        this.composition.category_name = category.text
      }
    }
  },

  mounted () {
    core.index()
    this.getTypes()
  },

  methods: {

    async myProvider (ctx) {
      return await AdminTemplateService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableTemplates.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getTypes () {
      this.fetching.types = true
      await SharedListService.getTypes().then(({ data }) => {
        this.list.types = data.map(({ id, type_name }) => ({
          text: type_name,
          value: id
        }))
        this.fetching.types = false
      })
    },

    async onSelectType (type) {
      return new Promise(resolve => {
        this.fetching.categories = this.state.busy = true
        this.composition.category_id = null
        this.list.categories = []
        this.$refs.formComposition.reset()
        SharedListService.getCategories(`type=${type}`).then(({ data }) => {
          this.list.categories = data.map(({ id, category_name, is_parent, is_package, maximum_slot }) => ({
            text: category_name,
            value: id,
            is_parent: is_parent,
            is_package: is_package,
            maximum_slot: maximum_slot
          }))
          this.fetching.categories = this.state.busy = false
          if (data.length > 0) {
            resolve(true)
          }
        })
      })
    },

    onShowAddFormTemplate () {
      this.state.editing = false
      this.state.editingComposition = false

      this.composition.id = null
      this.composition.name = null
      this.composition.type_id = null
      this.composition.type_name = null
      this.composition.category_id = null
      this.composition.category_name = null
      this.composition.is_parent = 0
      this.composition.is_package = 0
      this.composition.is_required = 1

      this.template.id = null
      this.template.template_name = null
      this.template.use_file_input = 1
      this.template.compositions = []
      this.template.active = 1

      this.$bvModal.show('modal-template')
    },

    onShowEditFormTemplate (template) {
      this.state.editing = true

      this.composition.id = null
      this.composition.name = null
      this.composition.type_id = null
      this.composition.type_name = null
      this.composition.category_id = null
      this.composition.category_name = null
      this.composition.is_parent = 0
      this.composition.is_package = 0
      this.composition.is_required = 1

      this.template.id = template.id
      this.template.template_name = template.template_name
      this.template.use_file_input = template.use_file_input
      this.template.compositions = template.compositions.map((composition, id) => {
        composition.id = Number(id) + 1
        return this.formatToComposition(composition)
      })
      this.template.active = template.active

      this.$bvModal.show('modal-template')
    },

    onShowUseFormTemplate (template) {
      this.state.editing = false

      this.composition.id = null
      this.composition.name = null
      this.composition.type_id = null
      this.composition.type_name = null
      this.composition.category_id = null
      this.composition.category_name = null
      this.composition.is_parent = 0
      this.composition.is_package = 0
      this.composition.is_required = 1

      this.template.id = null
      this.template.template_name = `Copy of ${template.template_name}`
      this.template.use_file_input = template.use_file_input
      this.template.compositions = template.compositions.map((composition, id) => {
        composition.id = Number(id) + 1
        return this.formatToComposition(composition)
      })
      this.template.active = 1

      this.$bvModal.show('modal-template')
    },

    async onValidateFormTemplate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formTemplate.validate().then(async success => {
        const compositions = this.template.compositions.filter(composition => composition.is_parent === 1)
        if (success && compositions.length === 1) {
          let text = 'Do you really want to create template?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormTemplate()
              } else {
                return this.onPostFormTemplate()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$refs.formComposition.setErrors({
            compositions: [
              'Please include at least one parent on this template.'
            ]
          })

          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostFormTemplate () {
      return new Promise(resolve => {
        AdminTemplateService.post(this.template).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-template')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableTemplates.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formTemplate.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutFormTemplate () {
      return new Promise(resolve => {
        AdminTemplateService.put(this.template).then(({ data }) => {
          this.$bvModal.hide('modal-template')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableTemplates.localItems, { id: data.template.id })
            row.template_name = data.template.template_name
            row.compositions = data.template.compositions
            row.active = data.template.active
            row.updated_at = data.template.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formTemplate.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    typeFormatter (type) {
      return this.findValueOfKey(this.list.types, type)
    },

    categoryFormatter (category) {
      return this.findValueOfKey(this.list.categories, category)
    },

    formatToComposition (composition) {
      return {
        id: composition.id,
        name: composition.name,
        type_id: composition.type_id,
        type_name: composition.type_name,
        category_id: composition.category_id,
        category_name: composition.category_name,
        is_parent: Number(composition.is_parent),
        is_package: Number(composition.is_package),
        is_required: Number(composition.is_required)
      }
    },

    findCompositionIndex (composition) {
      return this.template.compositions.findIndex(row => composition.id === row.id)
    },

    swapElements (arrayOf, indexFrom, indexTo) {
      const temporaryFrom = arrayOf[indexFrom]
      arrayOf[indexFrom] = arrayOf[indexTo]
      arrayOf[indexTo] = temporaryFrom
      return arrayOf
    },

    swapUpPosition (index) {
      this.template.compositions = this.swapElements(this.template.compositions, index, index - 1)
      this.$refs.tableCompositions.refresh()
    },

    swapDownPosition (index) {
      this.template.compositions = this.swapElements(this.template.compositions, index, index + 1)
      this.$refs.tableCompositions.refresh()
    },

    onClearComposition () {
      this.composition.id = null
      this.composition.name = null
      this.composition.type_id = null
      this.composition.type_name = null
      this.composition.category_id = null
      this.composition.category_name = null
      this.composition.is_parent = 0
      this.composition.is_package = 0
      this.composition.is_required = 1
      this.list.categories = []

      this.$refs.formComposition.reset()
      this.state.editingComposition = false
    },

    onEditComposition (composition) {
      this.state.editingComposition = true
      this.composition.id = composition.id
      this.composition.name = composition.name
      this.composition.type_id = composition.type_id
      this.composition.type_name = composition.type_name
      this.composition.is_parent = Number(composition.is_parent)
      this.composition.is_package = Number(composition.is_package)
      this.composition.is_required = Number(composition.is_required)

      this.onSelectType(this.composition.type_id).then(() => {
        this.composition.category_id = composition.category_id
        this.composition.category_name = composition.category_name
      })
    },

    onRemoveComposition (composition) {
      this.template.compositions = this.template.compositions.filter(row => composition.id !== row.id)
    },

    async onSaveComposition (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formComposition.validate().then(async success => {
        if (success) {
          await new Promise(resolve => {
            const id = this.template.compositions.length + 2
            if (this.composition.id) {
              const comPosition = this.findCompositionIndex(this.composition)
              if (comPosition >= 0) {
                this.template.compositions[comPosition] = this.formatToComposition(this.composition)
              }
            } else {
              this.composition.id = id
              this.template.compositions.push(this.formatToComposition(this.composition))
            }

            this.template.compositions.sort(function (a, b) { return b.is_parent - a.is_parent })

            resolve(this.composition)
          }).then(() => {
            this.onClearComposition()
          })
        }
      })
    }
  }
}
</script>
